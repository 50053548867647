import { gql, useQuery } from '@apollo/client';

const GET_UNAVAILABLE_DATES = gql`
  query GetUnavailableDates {
    blockedDates: getOrderServiceDateBlock {
      dateBlock
      timeBlock
    }
  }
`;

interface GetUnavailableDates {
  blockedDates: { 
    dateBlock: string 
    timeBlock: string
  }[];
}

export function useUnavailableDates() {
  return useQuery<GetUnavailableDates>(GET_UNAVAILABLE_DATES, {
    fetchPolicy: 'cache-first',
    context: {
      uri: process.env.REACT_APP_VISTORIA_SYNC_GRAPHQL,
    },
  });
}
